<template>
  <v-menu
    v-model="menu"
    min-width="200"
    offset-y
    bottom
    left
    :close-on-content-click="closeOnContentClick"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon data-testid="rightMenuBtn" v-on="on">
        <StoreAvatar
          v-if="mobile && hasMultipleStores"
          :store-id="store.id"
          :size="32"
        ></StoreAvatar>
        <v-icon v-else v-text="'$vuetify.icons.more'"></v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <!-- Lock -->
      <!-- if teammode and active employee -->
      <v-list-item
        v-if="showLock"
        ripple
        @click="$store.dispatch('auth/lockTeamMode')"
      >
        <v-list-item-title>Lock</v-list-item-title>
        <v-list-item-action>
          <v-icon>$vuetify.icons.unlock</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        v-if="showUnlock"
        ripple
        @click="$store.dispatch('auth/employeeAccessNeeded')"
      >
        <v-list-item-title>Unlock</v-list-item-title>
        <v-list-item-action>
          <v-icon>$vuetify.icons.unlock</v-icon>
        </v-list-item-action>
      </v-list-item>

      <EmployeeAction v-if="showTimesheet" v-slot="{ handleAction }">
        <v-list-item ripple @click="handleAction(onTimeSheetClick)">
          <v-list-item-title>Time sheet</v-list-item-title>
          <v-list-item-action>
            <v-icon>$calendarClock</v-icon>
          </v-list-item-action>
        </v-list-item>
      </EmployeeAction>

      <!-- Store selector -->
      <!-- if !teammode OR active employee -->
      <MobileStoreSelect
        v-if="mobile && !teamMode"
        v-model="storeSelectDialogModel"
      />

      <!-- Custom items using props -->
      <template v-for="(item, idx) of items">
        <EmployeeAction
          v-if="item.managerOnly"
          :key="idx"
          v-slot="{ handleAction, isDisabled, listeners }"
          check-manager
        >
          <v-list-item
            :key="idx"
            ripple
            v-on="listeners"
            @click="() => handleAction(() => handleClick(item.to))"
          >
            <v-list-item-title
              :class="{ 'text--disabled': isDisabled }"
              v-text="item.title"
            />
            <v-list-item-subtitle
              v-if="item.subtitle"
              :class="{ 'text--disabled': isDisabled }"
              v-text="item.subtitle"
            />
            <v-list-item-action v-if="item.icon">
              <v-icon v-text="item.icon" />
            </v-list-item-action>
          </v-list-item>
        </EmployeeAction>
        <v-list-item v-else :key="idx" ripple :to="item.to">
          <v-list-item-title v-text="item.title" />
          <v-list-item-subtitle v-if="item.subtitle" v-text="item.subtitle" />
          <v-list-item-action v-if="item.icon">
            <v-icon v-text="item.icon" />
          </v-list-item-action>
        </v-list-item>
      </template>

      <!-- Custom items using slots -->
      <slot name="items"></slot>

      <!-- Settings -->
      <EmployeeAction
        v-slot="{ handleAction, isDisabled, listeners }"
        check-manager
      >
        <v-list-item
          ripple
          :inactive="!allSettingsLink"
          :disabled="!allSettingsLink"
          v-on="listeners"
          @click="() => handleAction(() => handleClick(allSettingsLink))"
        >
          <v-list-item-content>
            <v-list-item-title :class="{ 'text--disabled': isDisabled }"
              >All Settings</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </EmployeeAction>

      <!-- Software updates -->
      <v-list-item v-if="$raiPos" ripple :to="{ name: 'SoftwareUpdateView' }">
        <v-list-item-content>
          <v-list-item-title>Software updates</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Help Center -->
      <v-list-item ripple href="https://help.resaleai.com" target="_raihelp">
        <v-list-item-content>
          <v-list-item-title>Help Center</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item-group>
        <v-divider />
        <!-- About -->
        <v-list-item
          ripple
          :to="{ name: 'about' }"
          data-test="rai-right-menu-about"
        >
          <v-list-item-content>
            <v-list-item-title>About ResaleAI</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Refresh -->
        <v-list-item
          ripple
          data-test-ref="btn-refresh"
          data-test="rai-right-menu-refresh"
          @click="handleRefresh"
        >
          <v-list-item-content>
            <v-list-item-title>Refresh</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>$refresh</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>

      <!-- Sign out & password change for user accounts only -->
      <template v-if="showSignout">
        <v-list-item-group>
          <v-divider />
          <v-list-item @click="handleChangePassword">
            <v-list-item-title>Change password</v-list-item-title>
          </v-list-item>
          <v-list-item data-testid="signOutBtn" @click="handleSignout">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import get from "lodash/get";
import { mapActions, mapState } from "vuex";
import EmployeeAction from "@/components/employees/EmployeeAction.js";
import StoreAvatar from "@/components/StoreAvatar";

export default {
  name: "RightMenu",
  components: {
    MobileStoreSelect: () => import("@/components/MobileStoreSelect"),
    EmployeeAction,
    StoreAvatar,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    stores: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    aboutDialog: false,
    menu: false,
    printerSelectDialogModel: false,
    clockOutDialogModel: false,
    storeSelectDialogModel: false,
  }),
  inject: {
    currentStore: {
      default() {
        return () => [];
      },
    },
    getStores: {
      default() {
        return () => [];
      },
    },
  },
  computed: {
    ...mapState("ui", ["dark", "loading"]),
    ...mapState("auth", ["teamModeLock", "teamMode", "employeeId"]),
    allSettingsLink() {
      return this.store
        ? {
            name: "settings.home",
            params: { storeId: this.store.id },
          }
        : null;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    // Add any other dialog v-model value names here and they
    // will be automatically watched
    dialogModels() {
      return [
        "printerSelectDialogModel",
        "clockOutDialogModel",
        "storeSelectDialogModel",
      ];
    },
    // If any of the dialogs are open, return `false`.
    // This prevents the RightMenu from closing and hiding the dialog
    closeOnContentClick() {
      return !this.dialogModels.map((m) => get(this, m)).some((e) => e);
    },
    mobileAndTeamMode() {
      return this.mobile && this.teamMode;
    },
    showPrinterSelect() {
      return this.mobileAndTeamMode && this.$raiTablet;
    },
    showLock() {
      return this.mobileAndTeamMode && this.employeeId;
    },
    showUnlock() {
      return this.mobileAndTeamMode && !this.employeeId;
    },
    showTimesheet() {
      return (
        this.mobileAndTeamMode &&
        this.store?.usesExternalScheduler &&
        this.store?.usesTimeclock
      );
    },
    showSignout() {
      return !this.$raiPos || this.$raiPos?.isPosProxy;
    },
    hasMultipleStores() {
      return (this.getStores()?.length || 0) > 1;
    },
    store() {
      return this.currentStore();
    },
  },
  created() {
    // When any of the dialogModels change to false, close the menu.
    this.dialogModels.map((model) => {
      this.$watch(model, function (v) {
        if (!v) {
          this.menu = false;
        }
      });
    });
    if (process.env.NODE_ENV !== "production") {
      window.$signOut = this.signOut;
    }
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    ...mapActions("ui", ["startLoading", "stopLoading"]),
    ...mapActions("snackbar", ["showSnackbar"]),
    handleSignout() {
      this.signOut()
        .then(() => this.$router.push({ name: "auth.login" }))
        .then(() => this.$apollo.getClient().resetStore())
        .then(() => this.showSnackbar({ text: "You have signed out." }));
    },
    handleChangePassword() {
      this.$router.push({ name: "settings.change-password" });
    },
    handleRefresh() {
      window.location.reload();
    },
    toggleLoading() {
      return this.loading ? this.stopLoading() : this.startLoading();
    },
    handleClick(route) {
      this.$router.push(route);
    },
    onTimeSheetClick() {
      this.$store.dispatch("teamMode/showTimeSheetDialog");
      this.$store.dispatch("teamMode/showEmployeeWelcomeDialog");
    },
  },
};
</script>
